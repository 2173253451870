<template>
    <div class="pa-3 fill-height">
        <v-card flat class="mt-3 fill-height" v-if="rptId != ''">
            <metabase type="question" width="100%" height="100%" titled :resource="rptId" :params="params" />
        </v-card>
    </div>
</template>

<script>
import Metabase from '@/components/Metabase.vue';
import { _st } from '@/softech';

export default {
    data: () => ({
        rptId       : '',
        params      : {}
    }),
    mounted() {
        this.loadParams();
    },
    methods: {
        loadParams() {
            this.params = {};

            if( _st.isNUE( this.$route.query ) )
                return;

            let p = {};

            for( let param in this.$route.query ) {
                if( _st.isNUE( this.$route.query[param] ) ) {
                    p[param] = null;
                    continue;
                }
                else if( param == 'rptId' ) {
                    this.rptId = this.$route.query[param];
                    continue;
                }
                else if( param == 'useDate' || param == 'pickupDate' || param == 'wantDate' || param == 'returnDate' ) {
                    p[param] = this.$route.query[param];
                    continue;
                }

                p[param] = this.$route.query[param].split(',');
            }

            this.params = JSON.parse( JSON.stringify( p ) );
            console.log(this.params);
        }
    },
    components: {
        Metabase
    }    
}
</script>

<style lang="scss" scoped>

</style>